import React, { useEffect, useState } from "react";
import "./index.css";
import Container from "../General/Container";
import Logo from "../../assets/images/logo.png";
import NpMobileLogo from "../../assets/images/logo/NpLogoMobile.svg";
import { headerTitleJSON } from "../constants";
import Strip from "../Strip";
import EnquiryModal from "../General/EnquiryModal/index";
import MobileNav from "./MobileNav";
import CloseIcon from "../../assets/images/icons/CloseIcon.png";
import HamburgerMenu from "../../assets/images/icons/HamburgerMenu.png";

function Header() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [strip, setStrip] = useState(true);

  const headerTitle = headerTitleJSON.find(
    ({ url }) => url === window.location.pathname
  );

  // function showStrip() {
  //   const max_date = new Date("2024-04-28T16:00:00");
  //   const now_date = new Date();
  //   return Boolean(max_date - now_date > 0);
  // }

  // useEffect(() => {
  //   showStrip() && setStrip(true);
  // }, []);

  useEffect(() => {
    if (headerTitle?.text) {
      document.title = "NowPurchase || " + headerTitle.text;
    } else document.title = "NowPurchase || Home";
  }, [headerTitle]);

  useEffect(() => {
    const handleScroll = () => {
      // Strip will only be visible when scrollY is 0 (at the top of the page)
      setStrip(window.scrollY == 0);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <EnquiryModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      {/* {strip && <Strip />} */}
      <div className="headerNew">
        <Container>
          <div className="headerWrapper">
            <div className="logoWrapper">
              <a className="logoMain" href="/">
                <img src={NpMobileLogo} loading="lazy" alt="logo" />
              </a>
              {headerTitle?.text && (
                <div className="pageTitleNew">{headerTitle?.text}</div>
              )}
            </div>

            <nav className={`navigationWrapper`}>
              <ul>
                <li>
                  <a href="/marketplace" id="Header_Services_Marketplace">
                    Marketplace
                  </a>
                </li>
                <li>
                  <a href="/application" id="Header_Services_MetalCloud">
                    MetalCloud
                  </a>
                </li>
                <li>
                  <a href="/scrap-recycling" id="Header_Services_MetalCloud">
                    Scrap Recycling
                  </a>
                </li>
                <li className="seperator">|</li>
                <li>
                  <a href="/culture" className="culture">
                    Culture
                  </a>
                </li>
                <li className="seperator">|</li>
                <li>
                  <button className="hiringBtnText">
                    <a href="/careers" className="careers">
                      We are Hiring!
                    </a>
                  </button>
                </li>
              </ul>
            </nav>
            {isNavOpen && <MobileNav setModalOpen={setModalOpen} />}
            <div className="menuWrapper">
              <button
                onClick={() => setModalOpen(true)}
                href="#getInTouchForm"
                className="getInTouchBtn"
              >
                Get In Touch
              </button>
              <div
                className="hamburgerMenu"
                onClick={() => setIsNavOpen(!isNavOpen)}
              >
                <img src={isNavOpen ? CloseIcon : HamburgerMenu} alt="" />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Header;

import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.css";
import Container from "../../General/Container";
import RightArrow from "../../../assets/images/icons/rgt_arrow_vector.png";
import NowIcon from "../../../assets/images/home/nowtextIcon.png";
import EnquiryModal from "../../General/EnquiryModal";
import {
  IMG_PREFIX,
  MarketPlaceMobileTabImages,
  MarketPlaceTabImages,
  ScrapRecyclingTabImages,
  TechSolutionsTabImages,
  imagePaths,
  metalCloudMobileTabs,
  metalCloudTabs,
  servicesTabList,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import GenericGridDisplay from "./GenericGridDisplay";
import { LogoContainer } from "../../../pages/NewsRoom";

// Helper function to detect mobile screens
const isMobileScreen = () => window.innerWidth < 768;

function TechnologyDriven() {
  const aosRef = useRef(null);
  const tabListRef = useRef(null);
  const tabsRef = useRef([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(isMobileScreen());
  const [tabIndex, setTabIndex] = useState(0);
  const [isAutoplay, setIsAutoplay] = useState(true); // Autoplay state
  const navigate = useNavigate();

  useEffect(() => {
    // This event listener triggers once all images and content have loaded
    window.onload = () => {
      setTabIndex(0); // Open the desired tab once the page is fully loaded
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(isMobileScreen());

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Autoplay effect
  useEffect(() => {
    let interval;
    let tabCount = servicesTabList.length;
    if (isAutoplay && !isMobile) {
      interval = setInterval(() => {
        setTabIndex((prevIndex) => (prevIndex + 1) % tabCount); // Cycle through tabs
      }, 10000); // 10s seconds per tab
    }

    return () => clearInterval(interval); // Cleanup on unmount
  }, [isAutoplay]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    if (aosRef.current) {
      observer.observe(aosRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (aosRef.current) {
        observer.unobserve(aosRef.current);
      }
    };
  }, []);

  // Function to render the current tab content
  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <GenericGridDisplay
            headerText={
              <>
                All the <strong>Raw Materials</strong> you Need, just a Click
                Away
              </>
            }
            onBtnClick={() => navigate("/marketplace")}
            btnText="Explore All"
            imagesTab={
              isMobile ? MarketPlaceMobileTabImages : MarketPlaceTabImages
            }
            mainImg={imagePaths.MarketPlaceMainImg}
            tabIndex={tabIndex}
            mobileScreenClass="marketPlaceGrid"
          />
        );
      case 1:
        return (
          <GenericGridDisplay
            headerText={
              <>
                <strong>Standardized Metal Scrap</strong> for your Melting Needs
              </>
            }
            onBtnClick={() => navigate("/scrap-recycling")}
            btnText="Know More"
            imagesTab={ScrapRecyclingTabImages}
            mainImg={imagePaths.ScrapProcessingMainImg}
            tabIndex={tabIndex}
            mobileScreenClass="equallyDistributedGrid"
          />
        );
      case 2:
        return (
          <GenericGridDisplay
            headerText={
              <>
                <strong>Reliable Materials</strong>, Perfect Casts, Guaranteed
                every time
              </>
            }
            onBtnClick={() => navigate("marketplace?premium-private-labels")}
            btnText="Explore Now"
            imagesTab={[]}
            mainImg={
              isMobile
                ? imagePaths.PrivateLabelsMobileMainImg
                : imagePaths.PrivateLabelsMainImg
            }
            tabIndex={tabIndex}
          />
        );
      case 3:
        return (
          <GenericGridDisplay
            headerText={
              <>
                Your <strong>Melting Process</strong>, now made Reliable
              </>
            }
            onBtnClick={() => setModalOpen(true)}
            btnText="Book a Demo"
            imagesTab={isMobile ? metalCloudMobileTabs : metalCloudTabs}
            mainImg={imagePaths.MetalCloudSoftwareMainImg}
            tabIndex={tabIndex}
            mobileScreenClass="equallyDistributedGrid"
          />
        );
      case 4:
        return (
          <GenericGridDisplay
            headerText={
              <>
                <strong>Smart Tech</strong> for Instant Updates, keeping you
                Ahead
              </>
            }
            imagesTab={TechSolutionsTabImages}
            mainImg={imagePaths.TechSolutionsMainImg}
            tabIndex={tabIndex}
            mobileScreenClass="equallyDistributedGrid"
          />
        );
      default:
        return null;
    }
  };
  const scrollToTab = (index) => {
    if (!tabListRef.current || tabsRef.current.length === 0) return;

    const tabContainer = tabListRef.current;
    const tab = tabsRef.current[index];

    if (!tab) return;

    const tabContainerRect = tabContainer.getBoundingClientRect();
    const tabRect = tab.getBoundingClientRect();

    const tabOffsetLeft = tabRect.left - tabContainerRect.left;
    const tabContainerWidth = tabContainerRect.width;
    const tabWidth = tabRect.width;

    const scrollLeft = tabContainer.scrollLeft;
    const containerWidth = tabContainer.clientWidth;

    const offset = tabOffsetLeft - containerWidth / 2 + tabWidth / 2;

    const left = Math.max(
      0,
      Math.min(tabContainer.scrollWidth - containerWidth, scrollLeft + offset)
    );

    tabContainer.scrollTo({
      left,
      behavior: "smooth",
    });
  };

  const handleTabClick = (index) => {
    // Scroll to the selected tab if on mobile and overflow is hidden
    if (isMobile && tabListRef.current) {
      scrollToTab(index);
    }
    setTabIndex(index);
  };

  const renderedTabContent = useMemo(() => renderTabContent(), [tabIndex]);

  return (
    <>
      <EnquiryModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <div ref={aosRef} className="animated-section section pb-0">
        <div className="overflowSection fadedBackground">
          <Container>
            <div className="gatewaySection">
              <div className="gatewayTitle">
                The Future of Metal
                <br /> Manufacturing is <img src={NowIcon} alt="" />
              </div>
              <div className="gatewayText">
                Transforming Procurement & Production for Factories
              </div>
              <div className="gatewayBtn" onClick={() => setModalOpen(true)}>
                <a href="#getInTouchForm">Connect With Us</a>
                <img src={RightArrow} alt="" />
              </div>
            </div>
          </Container>
          <div ref={aosRef} className="businessSectionWrap">
            <Container>
              <div className="row companySection">
                <LogoContainer />
              </div>
              <div className="react-tabs">
                <div
                  ref={tabListRef}
                  className="react-tabs__tab-list"
                  id="tabList"
                >
                  {servicesTabList?.map((tab, idx) => {
                    return (
                      <div
                        ref={(el) => (tabsRef.current[idx] = el)}
                        className={`react-tabs__tab ${
                          tabIndex == idx ? "react-tabs__tab--selected" : ""
                        }`}
                        onClick={() => {
                          setIsAutoplay(false); // Disable autoplay
                          handleTabClick(idx);
                        }}
                        key={idx}
                      >
                        <div className="tab-container">
                          <div className="tab-icon">
                            <img
                              src={`${IMG_PREFIX}${tab?.icon}`}
                              alt={`${tab?.name}_img`}
                              loading="lazy"
                            />
                          </div>
                          <div className="tab-name">{tab?.name}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <section
                  className={`react-tabs__tab-panel--selected react-tabs__tab-panel ${
                    tabIndex == 0
                      ? "react-tabs__tab-panel-mobile1"
                      : tabIndex == 4
                      ? "react-tabs__tab-panel-mobile2"
                      : ""
                  } ${tabIndex == 2 ? "privateLabelTab" : ""}`}
                  data-selected-tab={tabIndex}
                >
                  {renderedTabContent}
                </section>

                {/* <GenericGridDisplay
                    headerText={
                      <>
                        <strong>Financial Flexibility</strong> that Works for
                        you, Powered by our Trusted{" "}
                        <strong>Credit Network</strong>
                      </>
                    }
                    imagesTab={[]}
                    mainImg={imagePaths.FinancingSolution}
                    tabIndex={tabIndex}
                  /> */}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default TechnologyDriven;

import React, { useEffect } from "react";
import "./index.css";
import MarketPlaceNavIcon from "../../assets/images/MarketPlaceNavIcon.png";
import MetalCloudNavIcon from "../../assets/images/MetalCloudNavIcon.png";
import ScrapRecyclingNavIcon from "../../assets/images/ScrapRecyclingNavIcon.png";
import RightHighlightedArrow from "../../assets/images/icons/rgt_arrow_vector_bl.png";

const MobileNav = ({ setModalOpen }) => {
  // Use useEffect to disable scroll when component is mounted (open)
  useEffect(() => {
    // Disable scroll
    document.body.style.overflow = "hidden";
    // Re-enable scroll when component is unmounted (closed)
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []); // Empty dependency array to run only once when the component mounts
  return (
    <div className="mobileNavWrapper">
      <div className="navContainer">
        <div className="headerText">Solutions</div>
        <div className="navSection">
          <div className="imageContainer">
            <img src={MarketPlaceNavIcon} alt="" />
            <span className="imageText">Marketplace</span>
          </div>
          <div className="btnSection">
            <div className="sectionText">
              <strong>One-stop</strong> Solution for Quality Procurement
            </div>
            <a href="/marketplace" className="section-btn">
              <button>Know More</button>
              <img src={RightHighlightedArrow} alt="" loading="lazy" />
            </a>
          </div>
        </div>
        <div className="navSection">
          <div className="imageContainer">
            <img src={MetalCloudNavIcon} alt="" />
            <span className="imageText">Metal Cloud</span>
          </div>
          <div className="btnSection">
            <div className="sectionText">
              Your Melting Process, Now Made <strong>Reliable</strong> !
            </div>
            <div href="/marketplace" className="section-btn">
              <button>Know More</button>
              <img src={RightHighlightedArrow} alt="" loading="lazy" />
            </div>
          </div>
        </div>
        <div className="navSection">
          <div className="imageContainer">
            <img src={ScrapRecyclingNavIcon} alt="" />
            <span className="imageText" style={{ top: "30%" }}>
              Scrap Recycling
            </span>
          </div>
          <div className="btnSection">
            <div className="sectionText">
              <strong>Rethinking</strong> Resources, <strong>Reshaping</strong>{" "}
              Sustainability
            </div>
            <a href="/scrap-recycling" className="section-btn">
              <button>Know More</button>
              <img src={RightHighlightedArrow} alt="" loading="lazy" />
            </a>
          </div>
        </div>
      </div>
      <div className="navContainer">
        <a href="/culture" className="headerText">
          <span>Culture</span>
          <img className="grayArrow" src={RightHighlightedArrow} alt="" />
        </a>
      </div>
      <div className="navContainer">
        <a href="/careers" className="headerText">
          <span>We are Hiring</span>
          <img className="grayArrow" src={RightHighlightedArrow} alt="" />
        </a>
      </div>
      <button
        onClick={() => setModalOpen(true)}
        href="#getInTouchForm"
        className="getInTouchBtn"
      >
        Get In Touch
      </button>
    </div>
  );
};

export default MobileNav;
